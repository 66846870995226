var letsTalk = (function () {
	var $popup = $(".popup-apartment-form");

	if ($popup.length) run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$('.lets-talk-trigger').click(showForm);
	}
	
	function showFormChild(data) {
	//	console.log(data);

		$popup.find(".popup-container-image").css("background-image","url("+data.photo+")");
		$popup.addClass("half");
		
		$popup.find(".popup-pid").val(data.pid);
		$popup.find(".popup-atitle").val(data.atitle);
		$popup.find(".popup-thankyou").val(data.thankyou);

		$popup.find(".popup-title").html(data.projectname);
		$popup.find(".popup-subtitle").html(data.atitle);
		$popup.find(".popup-subsubtitle").html(data.floor);
		$popup.find(".popup-text").addClass("hide");
		if (data.text) {
			$popup.find(".popup-text").html(data.text);
			$popup.find(".popup-text").removeClass("hide");
		}

		$popup.addClass("show");
		setTimeout(function() {$(window).trigger('resize'); }, 10)	;

	}

	function showForm() {
		var $panel = ($(this).closest('.lets-talk-wrapper').find('.search-panel'));
		$popup.addClass("half");
	//	$popup.find(".popup-container-image").find("img").attr("src",$panel.attr('data-photo'));
		
		$popup.find(".popup-pid").val($panel.attr("data-pid"));
		$popup.find(".popup-atitle").val($panel.attr("data-atitle"));
		$popup.find(".popup-thankyou").val($panel.attr("data-thankyou"));

		$popup.find(".popup-title").html($panel.attr("data-projectname"));
		$popup.find(".popup-subtitle").html($panel.attr("data-atitle"));
		$popup.find(".popup-subsubtitle").html($panel.attr("data-floor"));
		$popup.find(".popup-text").addClass("hide");
		if ($panel.find(".search-panel-text").text()) {
			$popup.find(".popup-text").html($panel.find(".search-panel-text").text());
			$popup.find(".popup-text").removeClass("hide");
		}

		$popup.addClass("show");
		$(window).trigger('resize');
				
	}

	return {
		showFormChild: showFormChild	
	};

})();