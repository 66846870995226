(function () {
	run();

	function run() {
		init();
	}

	function init() {
		$('.selectric-dropdown').selectric();
	}
})();