(function ($w) {
	var isMobile = false;
	var data = {
		fullAmount: 0,
		firstPayment: 0,
		firstPaymentPercentage: 0,
		loanAmount: 0,
		interestRate: 0,
		interestRateDefault:0,
		loanPeriod: 0,
		currency:''
	};
	var result = 0;
	var $aside = $('.aside');
	var $footer = $('.footer');
	var $inputs = $('.component-calculator');
	var $fullAmount = $inputs.find('.full-amount');
	var $firstPayment = $inputs.find('.first-payment');
	var $loanAmount = $inputs.find('.loan-amount');
	var $interestRate = $inputs.find('.interest-rate');
	var $loanPeriod = $inputs.find('.loan-period');

	data.interestRateDefault = $inputs.find('.interest-rate').val();
	data.interestRate = data.interestRateDefault;
	data.firstPaymentPercentage = $inputs.find('.calculator_min_payment_percentage').val();
	data.currency = $inputs.find('.calculator_currency').val();

	if ($('.component-calculator').length) run();

	function run() {
		attachEvents();
	}

	function pmt_calc( intrest_rate , months , principal_value , fv ) {
		pmt = Math.round( (principal_value * intrest_rate) / (1 - Math.pow(1 + intrest_rate, -months))*100) / 100;
		return pmt;
}


	function attachEvents() {
		$('.component-calculator').submit(calculateLoan);
		$('.component-calculator').find('.component-input').keyup(automaticUpdate);
		$('.component-calculator').find('.component-input').change(validateDataChange);
	//	$loanPeriod.change(automaticUpdate);
	}

	function validateDataChange(e) {
		if (!$this.hasClass('interest-rate')) $this.val(MakeDecimal(removeCommasPoints($this.val())));
		collectData();
		validateData();
	}

	function automaticUpdate(e) {
		setIsMobile();
		//collectData();
		$this = $(this);


		if (!data.fullAmount) data.fullAmount=0;
		
		if ($this.hasClass('full-amount')) {
			collectData();
			data.firstPayment = parseInt(data.fullAmount*data.firstPaymentPercentage/100);
			$firstPayment.val(MakeDecimal(data.firstPayment));
			
			data.loanAmount = data.fullAmount - data.firstPayment;
			$loanAmount.val(MakeDecimal(data.loanAmount));
			collectData();
		//	console.log("Full Amount = "+data.fullAmount+" / First Payment = "+data.firstPayment+" / Laon Amount = "+data.loanAmount+" / Interest Rate = "+data.interestRate + "/ Loan Period = "+data.loanPeriod);
		}

		return true;
	}

	function calculateLoan(e) {
		e.preventDefault();
		setIsMobile();
		collectData();
		validateData();
		showResult();
	}

	function setIsMobile() {
		isMobile = $w.width() < 768 ? true : false;
	}

	function collectData() {
		var fullAmount='';
		var firstPayment='';
		var loanAmount='';
		var interestRate='';
		var loanPeriod=12;
		fullAmount = $('.component-calculator').find('.full-amount').val();
		firstPayment = $('.component-calculator').find('.first-payment').val();
		loanAmount = $('.component-calculator').find('.loan-amount').val();
		interestRate = $('.component-calculator').find('.interest-rate').val();
		loanPeriod = $('.component-calculator').find('.loan-period').val();
		
		/*if(isMobile) {
			fullAmount = $footer.find('.full-amount').val();
			firstPayment = $footer.find('.first-payment').val();
			loanAmount = $footer.find('.loan-amount').val();
			interestRate = $footer.find('.interest-rate').val();
			loanPeriod = $footer.find('.loan-period').val();
		}
		else {
			fullAmount = $aside.find('.full-amount').val();
			firstPayment = $aside.find('.first-payment').val();
			loanAmount = $aside.find('.loan-amount').val();
			interestRate = $aside.find('.interest-rate').val();
			loanPeriod = $aside.find('.loan-period').val();
		}*/
		data.fullAmount = removeCommasPoints(fullAmount);
		data.firstPayment = removeCommasPoints(firstPayment);
		data.loanAmount = removeCommasPoints(loanAmount);
		data.interestRate = removeCommas(interestRate);
		data.loanPeriod = loanPeriod*12; // months
	}

	function removeCommasPoints(str) {
		var s = str;
		s = s.replace(/,/g, '');
		s = s.replace(/\./g, '');
		return s;
	}
	function removeCommas(str) {
		var s = str;
		s = s.replace(/,/g, '.');
		return s;
	}

	
	function MakeDecimal(Number) {
    Number = Number + "" // Convert Number to string if not
    Number = Number.split('').reverse().join(''); //Reverse string
    var Result = "";
    for (i = 0; i <= Number.length; i += 3) {
        Result = Result + Number.substring(i, i + 3) + ".";
    }
    Result = Result.split('').reverse().join(''); //Reverse again
    if (!isFinite(Result.substring(0, 1))) Result = Result.substring(1, Result.length); // Remove first dot, if have.
    if (!isFinite(Result.substring(0, 1))) Result = Result.substring(1, Result.length); // Remove first dot, if have.
    return Result;
  }

	function validateData() {
		//console.log("validateData");
		if (!data.interestRate || data.interestRate =="0" || data.interestRate > 10) {
			data.interestRate = data.interestRateDefault;
			$interestRate.val(MakeDecimal(data.interestRate));
		}

	//	console.log("First Payment = "+data.firstPayment + "/ Full Amount = "+data.fullAmount);
//		console.log(data.fullAmount*data.firstPaymentPercentage/100);
		if (parseInt(data.firstPayment) >= parseInt(data.fullAmount)) {
			data.firstPayment = data.fullAmount*data.firstPaymentPercentage/100;
			$firstPayment.val(MakeDecimal(data.firstPayment));
			//console.log("data.firstPayment = " + data.firstPayment + " > data.fullAmount = " + data.fullAmount);

		}
		if (parseInt(data.firstPayment) < parseInt((data.fullAmount*data.firstPaymentPercentage/100))) {
			data.firstPayment = data.fullAmount*data.firstPaymentPercentage/100;
			$firstPayment.val(MakeDecimal(data.firstPayment));
		//	console.log("data.firstPayment = " + data.firstPayment + " < data.fullAmount = " + data.fullAmount);
		} 

		data.loanAmount = data.fullAmount - data.firstPayment;
		$loanAmount.val(MakeDecimal(data.loanAmount));
		
		//console.log("Full Amount = "+data.fullAmount+" / First Payment = "+data.firstPayment+" / Laon Amount = "+data.loanAmount+" / Interest Rate = "+data.interestRate + "/ Loan Period = "+data.loanPeriod);

		// alter/validate data as u will
		/*var firstPaymentRatio = (data.firstPayment/data.fullAmount)*100;
		firstPaymentRatio = isNaN(firstPaymentRatio) ? 0 : firstPaymentRatio;
		data.firstPayment = firstPaymentRatio < 20 ? 20 : data.firstPayment;

		if(isMobile) {
			$footer.find('.first-payment').val(data.firstPayment);
		} 
		else {
			$aside.find('.first-payment').val(data.firstPayment);
		}*/
	}

	function showResult() {
		//var result = pmt_calc(2.5/100/12,20*12,80000,1) + " " + data.currency;
		var result = pmt_calc(data.interestRate/100/12,data.loanPeriod,data.loanAmount,1);
		result = MakeDecimal(parseInt(result))  + " " + data.currency;
		$('.component-calculator').find('.monthly-bill').text(result);
		$('.component-calculator').find('#calculator_result').show();
		//isMobile ? $footer.find('.monthly-bill').text(result) : $aside.find('.monthly-bill').text(result);
		//isMobile ? $footer.find('#calculator_result').show() : $aside.find('#calculator_result').show();
		//aside.togglePosition(true);

	}

})($(window));