var amenityIcon = (function () {
  var $amenityIcon = $('.amenity-icon-click');
  var $popup = $(".popup-wrapper-amenity");
  if ($amenityIcon.length) run();
  
	function run() {
		attachEvents();
	}

	function attachEvents() {
    $amenityIcon.click(amenityClicked);
  }
  
  function showAmenity(data) {
    $popup.find(".popup-amenity-photo").css("background-image","url(assets/images/amenities/"+data.photo+".jpg)");
    $popup.find(".popup-amenity-text").html(data.title);

    $popup.addClass("show");
    $(window).trigger('resize');
  }

  function amenityClicked() {
    var $amenity = $(this);
  //  console.log($amenity.data('amenityphoto'));
  //  console.log($amenity.data('amenitytitle'));
    
    $popup.find(".popup-amenity-photo").css("background-image","url(assets/images/amenities/"+$amenity.data('amenityphoto')+".jpg)");
    $popup.find(".popup-amenity-text").html($amenity.data('amenitytitle'));

    $popup.addClass("show");
    $(window).trigger('resize');

  }

  return {
    showAmenity: showAmenity
  }
})();