(function ($w) {
  var $fullHeightSlider = $('.full-height-slider');
  var isHomeSlider = false;
  var instaHomeSlider = null;
  var bannersHomeSlider = null;
  var $visibleNearbySlider = $('.visible-nearby-slider');
  var $defaultSlider = $('.default-slider');
  var $gallerSlider = $('.gallery');
  var fullHeightSlider = null;
  var debounceTime = 100;
  var debounceTimeout = null;
  var options = null;
  run();

  function run() {
    setOptions();
    init();
    updateHeight();
    updateHeightOnResize();
  }
  
  function init() {
    if ($fullHeightSlider.length &&  $fullHeightSlider.hasClass("home")) isHomeSlider = true;
    fullHeightSlider = $fullHeightSlider.royalSlider(options.fullHeightSlider).data('royalSlider');

    instaHomeSlider = $(".insta-home-slider").royalSlider(options.defaultSlider).data('royalSlider');
    bannersHomeSlider = $(".banners-home-slider").royalSlider(options.defaultSlider).data('royalSlider');
   // console.log(instaHomeSlider);
    
    $defaultSlider.royalSlider(options.defaultSlider);
    $visibleNearbySlider.flickity(options.visibleNearbySlider);
    $gallerSlider.royalSlider(options.gallerySlider);
  }

  function updateHeight() {
    if(isHomeSlider) {
      var bannersInstaWidth = $(".home-banners-insta-container").width();
      //console.log(bannersInstaWidth);
      if(window.innerWidth >= 1200) {
        $fullHeightSlider.height(bannersInstaWidth*1.666667 + 20);
        $(".home-banners").height(bannersInstaWidth/1);
        $(".home-insta").height(bannersInstaWidth/1.5);
        
      } else if (window.innerWidth >= 768 && window.innerWidth < 1200) {
        $fullHeightSlider.height(450);
        $(".home-banners").height($(".home-banners").width());
        $(".home-insta").height($(".home-banners").width());
      } else if (window.innerWidth < 768) {
        $fullHeightSlider.height(400);
        $(".home-banners").height($(".home-banners").width());
        $(".home-insta").height($(".home-banners").width());
        //$fullHeightSlider.height('');
      }
      if (bannersHomeSlider) bannersHomeSlider.updateSliderSize(true);
      if (instaHomeSlider) instaHomeSlider.updateSliderSize(true);
      fullHeightSlider.updateSliderSize(true);
    } else {
      $fullHeightSlider.height($w.height() - (+$fullHeightSlider.attr('data-subtracted-height')));
      if(fullHeightSlider) fullHeightSlider.updateSliderSize(true);
    }
  }

  function updateHeightOnResize() {
    if(fullHeightSlider != null) {
      $w.resize(function () {
        debounce(updateHeight);
      });
    }
  }

  function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }

  function setOptions() {
    options = {
      "defaultSlider": {
        controlNavigation: 'none',
        imageScaleMode: 'fill',
        // arrowsNav: false,
        arrowsNavAutoHide: true,
        keyboardNavEnabled: true,
        imageAlignCenter: true,
        transitionType: 'fade',
        loop: true,
        globalCaption: true,
        autoPlay: {
          enabled: true,
          pauseOnHover: true,
          delay: 3500,
          stopAtAction: false,
        }
      },
      "fullHeightSlider": {
        controlNavigation: 'none',
        imageScaleMode: 'fill',
        arrowsNavAutoHide: true,
        keyboardNavEnabled: true,
        imageAlignCenter: true,
        transitionType: 'fade',
        loop: true,
        globalCaption: true,
        autoPlay: {
          enabled: true,
          pauseOnHover: true,
          delay: 2000,
          stopAtAction: false,
        }
      },
      "visibleNearbySlider": {
        setGallerySize: true,
        initialIndex: 1,
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        bgLazyLoad: 1,
        autoPlay: false,
        arrowShape: { 
          x0: 10,
          x1: 60, y1: 50,
          x2: 65, y2: 50,
          x3: 15
        }
      },
      "gallerySlider": {
        controlNavigation: 'thumbnails',
        loop: true,
        autoScaleSlider: false,
        autoScaleSliderWidth: 1600,
        autoScaleSliderHeight: 1000,
        imageScaleMode: 'fill',
        navigateByClick: true,
        numImagesToPreload: 2,
        arrowsNav: false,
        keyboardNavEnabled: true,
        fadeinLoadedSlide: true,
        globalCaption: false,
        globalCaptionInside: false,
        thumbs: {
          appendSpan: true,
          firstMargin: false,
          paddingBottom: 0,
          spacing: 10
        }
      }
    };
  }
})($(window));