(function ($w) {
	var debounceTime = 50, debounceTimeout = 0;

	run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$('.component-header').click(toggleComponent);
		$w.resize(function () {
      debounce(resize)
		});
		resize();
	}

	function resize() {
		var h = $(".footer .component-instagram").width();
		
		$(".footer .component-instagram").css('height', h+'px');
		$(".footer .component-instagram .slider").css('height', h+'px');
		$(".footer .component-instagram .rsOverflow").css('height', h+'px');

		$(".footer .component-banner").css('height', h+'px');
		$(".footer .component-banner .slider").css('height', h+'px');
		$(".footer .component-banner .rsOverflow").css('height', h+'px');
		
	}

	function toggleComponent() {
		var $component = $(this).closest('.component');
		if($component.hasClass('active')) hideComponent($component);
		else showComponent($component)
		aside.togglePosition(true);
	}

	function showComponent($component) {
		var $otherComponents = $component.closest('.components-container').find('.component').not($component);
		$otherComponents.removeClass('active');
		$component.addClass('active');
	}

	function hideComponent($component) {
		$component.removeClass('active');
	}

	function debounce(fn) {
		clearTimeout(debounceTimeout);
		debounceTimeout = setTimeout(fn, debounceTime);
	}
})($(window));