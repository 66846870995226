var popupbanner = (function () {
	var $banner = $(".banner");
	var $popup = $(".popup-banner-form");
		
	if ($banner.length) run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$banner.click(bannerClicked);
		var $p = $(".banner.open");
		if ($p.length) {
			//console.log($p.find(".rsImg").attr("href"));
			setTimeout(function(){ openBanner($p); }, 2000);
		}
  }

  function bannerClicked() {
		openBanner($(this));
	}

	function openBanner($b) {
	//	var $b = $(this);
    $popup.find(".popup-container-image").find("img").attr("src",$b.attr('data-photo'));
    $popup.find(".popup-title").html($b.attr('data-title'));
    $popup.find(".popup-subtitle").html($b.attr('data-subtitle'));
    
    $popup.find(".popup-thankyou").val($b.attr('data-thankyou'));
		$popup.find(".popup-emailto").val($b.attr('data-email'));
		$popup.find(".popup-pid").val($b.attr('data-pid'));
    $popup.find(".popup-atitle").val($b.attr('data-title'));
		
		$popup.find(".popup-banner").val("1");
		$popup.find(".message").val($b.attr('data-message'));
    
		$popup.find(".popup-text").addClass("hide");
		if ($b.attr('data-text')) {
			$popup.find(".popup-text").html($b.attr('data-text'));
			$popup.find(".popup-text").removeClass("hide");
		}

    $popup.addClass("show");
		$(window).trigger('resize');
		setTimeout(function() {$(window).trigger('resize'); }, 100);
		setTimeout(function() {$(window).trigger('resize'); }, 200);
		setTimeout(function() {$(window).trigger('resize'); }, 400);
		setTimeout(function() {$(window).trigger('resize'); }, 600);
  }


})();