(function () {
	var $input = $('.blog-filter-element');
	var $blogs = $('.blog');
	var blogsHtml = '';
	if ($blogs.length) run();
	var firstRun = true;

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$input.keyup(filterElements);
		$('.blog-filter-submit').click(filterElements);
	}

	function filterElements() {
		if (firstRun) {
			firstRun = false;
			blogsHtml = $(".blogs").html();
		}
		$(".blogs").html(blogsHtml);
		$blogs = $('.blog');

		var searchTerm = $input.val().toLowerCase();
		var blogsToRemoveArray = [];
		$blogs.each(function () {
			var $blog = $(this);
			if($blog.find('.blog-title').text().toLowerCase().indexOf(searchTerm) == -1 && $blog.find('.blog-subtitle').text().toLowerCase().indexOf(searchTerm) == -1 && $blog.find('.blog-text').text().toLowerCase().indexOf(searchTerm) == -1) {
				blogsToRemoveArray.push($blog);
			}
		});

	//	console.log(blogsToRemoveArray);
		if (blogsToRemoveArray.length) {
			for (var i = 0; i < blogsToRemoveArray.length; i++) {
				blogsToRemoveArray[i].remove();
    //		console.log(i);
			}
		}

		$(window).trigger('resize'); 
	}
})();