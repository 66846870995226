(function ($w) {
	var feedbackTimeout = null;
	var feedbackTime = 5000;
	var isMobile = false;
	var data = {
		fullName: '',
		email: '',
		phoneNumber: '',
		country: ''
	};
	var $aside = $('.aside');
	var $footer = $('.footer');
	var $subscribeBtn = $('.subscribe-newsletter-btn');
	run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$('.component-newsletter').submit(subscribeToNewsletter);
	}

	function subscribeToNewsletter(e) {
		e.preventDefault();
		

		setIsMobile();
		collectData();
		processData(e);
	}

	function setIsMobile() {
		isMobile = $w.width() < 768 ? true : false;
	}

	function collectData() {
		var fullName = '';
		var email = '';
		var phoneNumber = '';
		var country = '';
		var project = '';
		if(isMobile) {
			fullName = $footer.find('.full-name').val();
			email = $footer.find('.email').val();
			phoneNumber = $footer.find('.phone-number').val();
			country = $footer.find('.country').val();
			project = $footer.find('.project').val();

		}
		else {
			fullName = $aside.find('.full-name').val();
			email = $aside.find('.email').val();
			phoneNumber = $aside.find('.phone-number').val();
			country = $aside.find('.country').val();
			project = $aside.find('.project').val();

		}
		data.fullName = fullName;
		data.email = email;
		data.phoneNumber = phoneNumber;
		data.country = country;
		data.project = project;
	}

	function processData(e) {
		if(isMobile) $footer.find('.feedback').text($footer.find('.newsletter_reply_wait').val());
		else $aside.find('.feedback').text($aside.find('.newsletter_reply_wait').val());

		e.preventDefault();
		disableSubmit();
		$.ajax({
			url: 'newsletter.php',
			method: 'POST',
			data: data,
			error: function (msg) {
				showFeedback(msg);
			},
			success: function (msg) {
				showFeedback(msg);
			}
		});
	}

	function disableSubmit() {
		$subscribeBtn.attr('disabled', 'disabled');
	}

	function enableSubmit() {
		$subscribeBtn.removeAttr('disabled');
	}

	function clearFields() {
		$('.full-name').val('');
		$('.email').val('');
		$('.phone-number').val('');
		$('.country').val('');
	}

	function hideComponent() {
		$('.component-newsletter.active .component-header').click();
	}

	function showFeedback(msg) {
	//	console.log(msg);
		//msg = 100;
		var $footerFeedback = $footer.find('.feedback');
		var $asideFeedback = $aside.find('.feedback');
		if(feedbackTimeout) clearTimeout(feedbackTimeout);

		if(isMobile)
			$footerFeedback.text($footer.find('.newsletter_reply_'+msg).val());
		else
			$asideFeedback.text($aside.find('.newsletter_reply_'+msg).val());

		if (msg == 100) { // Success
		feedbackTimeout = setTimeout(function () {
			$footerFeedback.text('');
			$asideFeedback.text('');
			clearFields();
			enableSubmit();
			hideComponent();
		}, feedbackTime);
		} else {
			enableSubmit();
		}
	}

})($(window));