(function ($w) {
	var debounceTime = 100;
  var debounceTimeout = null;
	var isOneColumn = false;

	if ($('.search-panel-text-wrapper').length) run();

	function run() {
		$w.resize(function () {
      debounce(arrangePanelsHeight);
    });

		arrangePanelsHeight();
	}

	function arrangePanelsHeight() {
		
		var nbreOfCols = 1;
		if (window.innerWidth > 991) nbreOfCols = 2;
		if (window.innerWidth > 1199) nbreOfCols = 3;
		//console.log(nbreOfCols);

		var minHeight = 0;
		$('.search-panel-text-wrapper').css("min-height","10px");
		$('.search-panel-text-wrapper').css("height","auto");


		if (nbreOfCols >1 ) {
			$('.search-panels').each(function () {
				var minHeight = 0;
				$sp = $(this);
				$sp.find('.search-panel-wrapper').each(function () {
					$p = $(this);
					if (($(this).height()-80) > minHeight) minHeight = $(this).height()-80;
				});
			//	console.log(minHeight);
				$sp.find('.search-panel-text-wrapper').css("height",minHeight+"px");
			});
		}
	}

	function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }

})($(window));