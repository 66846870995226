var googleMap = (function () {
	var map = null;
	var $map = $('#map');
	var googleMapStylesNoLandmarks = [
	 {
	   "featureType": "poi",
	   "elementType": "labels.text",
	   "stylers": [
		 {
		   "visibility": "off"
		 }
	   ]
	 }
  ];

	function init() {
		var googleMapStyles = [];
		if ($map.attr('data-lat') == '33.838833') googleMapStyles = googleMapStylesNoLandmarks;
        map = new google.maps.Map(document.getElementById('map'), {
		  zoom: +$map.attr('data-zoom'),
		  disableDefaultUI: true,
          center: { lat: +$map.attr('data-lat'), lng: +$map.attr('data-lng') },
          styles: googleMapStyles
		});
		//console.log($map.attr('data-lat')+" / "+$map.attr('data-lng'));
	}

	function addButton(area) {
		var btn = document.createElement('div');
        new Btn(btn, map, area);
        map.controls[google.maps.ControlPosition.TOP_RIGHT].push(btn);
	}

	function addMarker(area) {
		var marker = new google.maps.Marker({
          position: area.position,
          map: map,
          icon: 'assets/images/pin.png',
        });
	}

	function Btn(btn, map, area) {
		var controlUI = document.createElement('div');
		controlUI.style.backgroundColor = '#686868';
		controlUI.style.cursor = 'pointer';
		controlUI.style.marginBottom = '5px';
		controlUI.style.marginRight = '5px';
		controlUI.style.marginTop = '15px';
		controlUI.style.textAlign = 'center';
		controlUI.className = 'googleMapBtn';
		btn.appendChild(controlUI);

		var controlText = document.createElement('div');
		controlText.style.color = '#fff';
		if ($('body').css('direction') == "rtl") {
			controlText.style.fontFamily = 'Droid Arabic Naskh';
		} else {
			controlText.style.fontFamily = 'Raleway, sans-serif';
		}
		controlText.style.fontSize = '13px';
		controlText.style.lineHeight = '28px';
		controlText.style.paddingLeft = '10px';
		controlText.style.paddingRight = '10px';
		controlText.innerHTML = area.name;
		controlUI.appendChild(controlText);

		controlUI.addEventListener('click', function() {
		  map.setCenter(area.position);
		});
	}

	return {
		init: init,
		addButton: addButton,
		addMarker: addMarker
	};

})();