(function ($w) {
  var debounceTime = 100, debounceTimeout = 0;

  $cards = $(".cards-same-height");

  if ($cards.length > 1) run();

	function run() {
    attachEvents();
  }
  
  function attachEvents() {
    $w.resize(function () {
      debounce(resizeCards)
    });
    resizeCards();
  }

  function resizeCards() {
    $cards = $(".cards-same-height");
    var minHeightCard = 0;
    var minHeightCardText = 0;
    var minHeightCardTitle = 0;
    var minHeightCardTextWrapper = 0;
    //var minHeightCardSubTitle = 0;
    var minHeightCardBoxesWrapper = 0;
    var minHeightCardFooter = 0;
   // var minHeightButtonWrapper = 0;

    
    $cards.css("height", "auto");
    $cards.find(".card-text").css("height", "auto");
    $cards.find(".card-title").css("height", "auto");
    $cards.find(".card-text-wrapper").css("height", "auto");
    //$cards.find(".card-subtitle").css("height", "auto");
    $cards.find(".card-boxes-wrapper").css("height", "auto");
    $cards.find(".card-footer").css("height", "auto");
    //$cards.find(".button-link-wrapper").css("height", "auto");
   
    //card-text-wrapper
  //  console.log($( window ).width());
   // console.log(window.innerWidth);

   var isBlog = false;
    if (window.innerWidth > 991) {
      $.each($cards, function( key, value ) {
        if ($(this).hasClass("blog")) isBlog = true;
        if ($(this).find(".card-text").innerHeight() > minHeightCardText) minHeightCardText = $(this).find(".card-text").innerHeight();
        if ($(this).find(".card-title").innerHeight() > minHeightCardTitle) minHeightCardTitle = $(this).find(".card-title").innerHeight();
     //   if ($(this).find(".card-subtitle").innerHeight() > minHeightCardSubTitle) minHeightCardSubTitle = $(this).find(".card-subtitle").innerHeight();
        if ($(this).find(".card-boxes-wrapper").innerHeight() > minHeightCardBoxesWrapper) minHeightCardBoxesWrapper = $(this).find(".card-boxes-wrapper").innerHeight();
        if ($(this).find(".card-text-wrapper").innerHeight() > minHeightCardTextWrapper) minHeightCardTextWrapper = $(this).find(".card-text-wrapper").innerHeight();
        if ($(this).find(".card-footer").innerHeight() > minHeightCardFooter) minHeightCardFooter = $(this).find(".card-footer").innerHeight();
        //console.log($(this).find(".card-footer").innerHeight());
      });

      if (!isBlog) {
        $cards.find(".card-text").css("height", minHeightCardText+"px");
        $cards.find(".card-title").css("height", minHeightCardTitle+"px");
      }
      
      //$cards.find(".card-subtitle").css("height", minHeightCardSubTitle+"px");
      $cards.find(".card-boxes-wrapper").css("height", minHeightCardBoxesWrapper+"px");
      $cards.find(".card-text-wrapper").css("height", minHeightCardTextWrapper+"px");
      $cards.find(".card-footer").css("height", minHeightCardFooter+"px");

      $.each( $cards, function( key, value ) {
        if ($(this).innerHeight() > minHeightCard) minHeightCard = $(this).innerHeight();
      });
      $cards.css("height", minHeightCard+"px");
    }
  }

  function debounce(fn) {
		clearTimeout(debounceTimeout);
		debounceTimeout = setTimeout(fn, debounceTime);
	}

})($(window));