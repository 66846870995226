filter = (function () {
	var $panels = {};
	var pageId;
	var lg;

	run();
	//console.log($('.iframe')[0]);
	function run() {
		$('.filter-dropdown').change(reloadIframe);
		$('.filter-elements-wrapper').css("cursor", "pointer");
		$('.filter-elements-wrapper').click(togglePanels);
	}

	function togglePanels(e) {
		e.preventDefault();
		$panels = $('.iframe').contents().find('.search-panel-wrapper');
		$panels.removeClass('hide');	
		$(".filter-elements-wrapper").removeClass("active");

		//console.log($(this));
		if ($(this).hasClass('filter-elements-wrapper-available-apartments')) {
		//	console.log("Available Apartments");
			
			if ($(this).find('.filter-checkbox:checkbox:checked').length) {
				$(this).find('.filter-checkbox').prop('checked', false);
				//console.log("Show All Apartments");
				$('#all-apartments').prop('checked', true);
				$('.filter-elements-wrapper-all-apartments').addClass("active");
				$panels.removeClass('hide');

			} else {
				$(this).find('.filter-checkbox').prop('checked', true);
			//console.log("Show Available Apartments");
				$('#all-apartments').prop('checked', false);
				$('.filter-elements-wrapper-all-apartments').removeClass("active");
				$('.filter-elements-wrapper-available-apartments').addClass("active");
				$panels.removeClass('hide');
				$panels.filter('[data-available=false]').addClass('hide');

			}

		} else {
			//console.log("All Apartments");

			if ($(this).find('.filter-checkbox:checkbox:checked').length) {
				$(this).find('.filter-checkbox').prop('checked', false);
				//console.log("Show Available Apartments");
				$('#all-apartments').prop('checked', false);
				$('#available-apartments').prop('checked', true);
				$('.filter-elements-wrapper-all-apartments').removeClass("active");
				$('.filter-elements-wrapper-available-apartments').addClass("active");
				$panels.removeClass('hide');
				$panels.filter('[data-available=false]').addClass('hide');

			} else {
				$(this).find('.filter-checkbox').prop('checked', true);
				$('#available-apartments').prop('checked', false);
				//console.log("Show All Apartments");
				$panels.removeClass('hide');
				//$panels.filter('[data-available=false]').addClass('hide');

				$('#all-apartments').prop('checked', true);
				$('.filter-elements-wrapper-all-apartments').addClass("active");
			}
		}
	/*
		e.preventDefault();
		if ($(this).find('.filter-checkbox:checkbox:checked').length) {
			$(this).find('.filter-checkbox').prop('checked', false);
		} else {
			$(this).find('.filter-checkbox').prop('checked', true);
		}

		$panels = $('.iframe').contents().find('.search-panel-wrapper');

		$panels.removeClass('hide');	
		$(".filter-elements-wrapper").removeClass("active");

		$('.filter-checkbox:checked').each(function () {
			$('.filter-checkbox:checked').closest(".filter-elements-wrapper").addClass("active");
			$panels.filter('[data-'+getCheckboxType($(this))+'="false"]').addClass('hide');
		});
*/
 		if ($('.iframe')[0])	$('.iframe')[0].contentWindow.apartments.setIframeHeightResetPanels();
	}


	function reloadIframe() {
		$('.iframe').attr('src', 'template-project-block-apartments.php?page_id='+pageId+'&lg='+lg+'&sort_by='+$(this).val()+'&first_run=1');
		$('.filter-checkbox').prop('checked', false);
		$(".filter-elements-wrapper").removeClass("active");

		$('#available-apartments').prop('checked', true);
		$('.filter-elements-wrapper-available-apartments').addClass("active");

		$('#all-apartments').prop('checked', false);
		$('.filter-elements-wrapper-all-apartments').removeClass("active");
		
	}
	
	function setPageId($id) {
		pageId = $id
	}
	function setLg($lg) {
		lg = $lg;
	}

	function getCheckboxType($checkbox) {
		var checkboxType = '';
		if($checkbox.attr('data-available'))
			checkboxType = 'available';
		else if($checkbox.attr('data-terrace'))
			checkboxType = 'terrace';
		else if($checkbox.attr('data-triplex'))
			checkboxType = 'triplex';		
		else if($checkbox.attr('data-duplex'))
			checkboxType = 'duplex';
		else if($checkbox.attr('data-simplex'))
			checkboxType = 'simplex';
		return checkboxType;
	}

	function onIframeDocumentReady() {
		$panels = $('.iframe').contents().find('.search-panel-wrapper');
	}

	return {
		onIframeDocumentReady: onIframeDocumentReady,
		setPageId: setPageId,
		setLg: setLg
	};
})();