(function () {
	var search_area_unit= $(".search_area_unit").val();
	var search_price_currency= $(".search_price_currency").val();
	var firstRunArea = true;
	run();

	function run() {
		init();
		attachEvents();
	}

	function init() {
		initAreaSlider();
		initPriceSlider();
	}

	function attachEvents() {
		$('.switch-to-area').click(switchToUnit);
		$('.switch-to-currency').click(switchToCurrency);
	}

	function switchToUnit(e) {
		e.preventDefault();
		if (search_area_unit =="sqm") search_area_unit = "sqf";
		else search_area_unit = "sqm";
	//	console.log(search_area_unit);
		initAreaSlider();
		updateSessionAreaPrice();
	}
	
	function switchToCurrency(e) {
		e.preventDefault();
		if (search_price_currency =="euro") search_price_currency = "dollar";
		else search_price_currency = "euro";
		//console.log(search_price_currency);
		initPriceSlider();
		updateSessionAreaPrice();
	}
	function updateSessionAreaPrice() {
		//console.log("updateSessionAreaPrice");
		var data = {
			search_area_unit: search_area_unit,
			search_price_currency: search_price_currency
		};

		$.ajax({
			url: 'area-price.php',
			method: 'POST',
			data: data,
			error: function (msg) {
				showFeedback(msg);
			},
			success: function (msg) {
				showFeedback(msg);
			}
		});
	}
	function showFeedback(msg) {
		//console.log(msg);
	}

	function initAreaSlider() {
		var min = parseInt($(".search_area_min_"+search_area_unit).val());
		var max = parseInt($(".search_area_max_"+search_area_unit).val());

		var _switch_to_area_unit;
		var _selected_area_unit;
		
		if (search_area_unit=="sqm") _switch_to_area_unit = $(".search_sqf").val();
		if (search_area_unit=="sqf") _switch_to_area_unit = $(".search_sqm").val();

		if (search_area_unit=="sqm") _selected_area_unit = $(".search_sqm").val();
		if (search_area_unit=="sqf") _selected_area_unit = $(".search_sqf").val();

		$(".selected-area-unit").html(_selected_area_unit);

		$(".switch-to-area").html("("+$(".search_switch_to").val()+" "+_switch_to_area_unit+")");

		$(".search_area_unit").val(search_area_unit);


		var valueMin;
		var valueMax;
		if (!$( ".area-slider-range" ).html() && $( ".search_get_area_min" ).val()) valueMin = $( ".search_get_area_min" ).val();
		else valueMin = min;

		if (!$( ".area-slider-range" ).html() && $( ".search_get_area_max" ).val()) valueMax = $( ".search_get_area_max" ).val();
		else valueMax = max;

		if ($( ".area-slider-range" ).html()) $( ".area-slider-range" ).slider( "destroy" );

		$( ".area-slider-range" ).slider({
		    range: true,
		    min: min,
		    max: max,
		    values: [ valueMin, valueMax ],
		    create: function (event, ui) {
		    	var values = $( ".area-slider-range" ).slider( "option", "values" );
			$( ".area-slider-start" ).text(MakeDecimal(values[0]));
		      $( ".area-slider-end" ).text(MakeDecimal(values[1]));
		    },
		    slide: function( event, ui ) {
		      $( ".area-slider-start" ).text(MakeDecimal(ui.values[ 0 ]));
		      $( ".area-slider-end" ).text(MakeDecimal(ui.values[ 1 ]));
		    }
		});
	}

	function initPriceSlider() {
//search_price_currency
		var min = parseInt($(".search_price_min_"+search_price_currency).val());
		var max = parseInt($(".search_price_max_"+search_price_currency).val());

		var _switch_to_price_currency;
		var _selected_price_currency;
		if (search_price_currency=="dollar") _switch_to_price_currency = $(".search_currency_euro").val();
		if (search_price_currency=="euro") _switch_to_price_currency = $(".search_currency_dollar").val();

		if (search_price_currency=="dollar") _selected_price_currency = $(".search_currency_dollar").val();
		if (search_price_currency=="euro") _selected_price_currency = $(".search_currency_euro").val();

		$(".selected-price-currency").html(_selected_price_currency);

		$(".switch-to-currency").html("("+$(".search_switch_to").val()+" "+_switch_to_price_currency+")");

		$(".search_price_currency").val(search_price_currency);


		var valueMin;
		var valueMax;
		if (!$( ".price-slider-range" ).html() && $( ".search_get_price_min" ).val()) valueMin = $( ".search_get_price_min" ).val();
		else valueMin = min;

		if (!$( ".price-slider-range" ).html() && $( ".search_get_price_max" ).val()) valueMax = $( ".search_get_price_max" ).val();
		else valueMax = max;

		if ($( ".price-slider-range" ).html()) $( ".price-slider-range" ).slider( "destroy" );


		$( ".price-slider-range" ).slider({
		    range: true,
		    min: min,
		    max: max,
		    values: [ valueMin, valueMax ],
		    create: function (event, ui) {
		    	var values = $( ".price-slider-range" ).slider( "option", "values" );
					$( ".price-slider-start" ).text(MakeDecimal(values[0]));
		      $( ".price-slider-end" ).text(MakeDecimal(values[1]));
		    },
		    slide: function( event, ui ) {
		      $( ".price-slider-start" ).text(MakeDecimal(ui.values[ 0 ]));
		      $( ".price-slider-end" ).text(MakeDecimal(ui.values[ 1 ]));
		    }
		});
	}

	function MakeDecimal(Number) {
    Number = Number + "" // Convert Number to string if not
    Number = Number.split('').reverse().join(''); //Reverse string
    var Result = "";
    for (i = 0; i <= Number.length; i += 3) {
        Result = Result + Number.substring(i, i + 3) + ".";
    }
    Result = Result.split('').reverse().join(''); //Reverse again
    if (!isFinite(Result.substring(0, 1))) Result = Result.substring(1, Result.length); // Remove first dot, if have.
    if (!isFinite(Result.substring(0, 1))) Result = Result.substring(1, Result.length); // Remove first dot, if have.
    return Result;
  }

})();