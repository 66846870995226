(function () {
	var $contactForm = $('.contact-form');
	var data = {
		fullName: '',
		email: '',
		phone: '',
		country: '',
		message: ''
	};
	var feedbackTimeout = null;
	var feedbackTime = 5000;
	run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$contactForm.submit(sendMessage);
	}

	function sendMessage(e) {
		e.preventDefault();
		disableSubmit();
		collectData();
		submitData(e);
	}

	function collectData() {
		data.fullName = $contactForm.find('.full-name').val();
		data.email = $contactForm.find('.email').val();
		data.phone = $contactForm.find('.phone').val();
		data.country = $contactForm.find('.country').val();
		data.message = $contactForm.find('.message').val();
		data.page_id = $contactForm.find('.contact_page_id').val();
		data.project_page_id = $contactForm.find('.contact_project_page_id').val();
		data.lg = $("header").data("lg");
		//console.log(data.lg);
	}

	function submitData(e) {
		e.preventDefault();
		//console.log($contactForm.find('.contact_reply_wait').val());
		$contactForm.find('.feedback').text($contactForm.find('.contact_reply_wait').val());
		$.ajax({
			url: 'contact.php',
			method: 'POST',
			data: data,
			error: function (msg) {
				showFeedback(msg);
			},
			success: function (msg) {
				showFeedback(msg);
			}
		});
	}

	function disableSubmit() {
		$contactForm.find('.contact-form-submit').attr('disabled', 'disabled');
	}

	function enableSubmit() {
		$contactForm.find('.contact-form-submit').removeAttr('disabled');
	}

	function clearContactForm() {
		$contactForm.find('.full-name').val('');
		$contactForm.find('.email').val('');
		$contactForm.find('.phone').val('');
		$contactForm.find('.country').val('');
		$contactForm.find('.message').val('');
	}

	function showFeedback(msg) {
	//	console.log(msg);
		if ($contactForm.find('.contact_reply_cookie').val() && msg=="100") {
			window.location = window.location.href+"&thanks=1"; 
		}

		if(feedbackTimeout) clearTimeout(feedbackTimeout);
		var $feedback = $contactForm.find('.feedback');
		if(msg == "100") {
			$feedback.text($contactForm.find('.contact_reply_success').val());
		}
		else {
			$feedback.text($contactForm.find('.contact_reply_error_0').val());
		}
		
		if (msg == 100) { // Success
			feedbackTimeout = setTimeout(function () {
				$feedback.text('');
				enableSubmit();
				clearContactForm();
				placeholder.fix();
			}, feedbackTime);
		} else {
			enableSubmit();
		}
	}

})();