var popupform = (function ($w) {
	var $popup = $(".popup-wrapper");
	var data = {};
	var feedbackTimeout = null;
	var feedbackTime = 5000;
	var debounceTime = 100, debounceTimeout = 0;

	if ($popup.length) run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
    $popup.find(".popup-close").click(close);
		$popup.find(".popup-background").click(close);
		$popup.find(".popup-amenity").click(close);
		$popup.find(".popup-form-fields").submit(submitForm);
		$w.resize(function () {
			debounce(checkPopupDimensions)
		});
		$w.scroll(function () {
			debounce(checkPopupDimensions)
		});
		checkPopupDimensions();
	}

	function checkPopupDimensions() {
		var st = $w.scrollTop();// $w.height()*0.05;
		//$popup.css("margin-top",st+"px");
		//console.log(st);
		// var w = window.innerWidth;
		// console.log(w);
		var wh = $w.height();
		$popup.each(function () {
			var $p = $(this);
			var h = $p.height();
			var mt = (wh - h)/2;
			$p.css("margin-top",st + mt+"px");
			if (mt < 0) {
				$p.css("margin-top",st +mt*2 +"px");
				
		//		$("html, body").animate({ scrollTop: 0 }, "fast");
			}
		});

	}

  function submitForm(e) {
		e.preventDefault();
    var $form = $(this);
    data.fullName = $form.find('.full-name').val();
		data.email = $form.find('.email').val();
		data.phone = $form.find('.phone').val();
		data.country = $form.find('.country').val();
    data.message = $form.find('.message').val();
    data.atitle = $form.find('.popup-atitle').val();
  	data.pid = $form.find('.popup-pid').val();
 		data.thankyou = $form.find('.popup-thankyou').val();
	  data.lg = $form.find('.popup-lg').val();
    data.senderror = $form.find('.popup-error').val();
    data.wait = $form.find('.popup-wait').val();
    data.banner = $form.find('.popup-banner').val();
		data.emailto = $form.find('.popup-emailto').val();
		
    var $feedback = $form.find('.feedback');
    $feedback.text(data.wait);
		if (data.banner) data.url = 'contact-banner.php';
		else data.url = 'contact-apartment.php';

		$form.find('.submit').addClass("disabled");

    $.ajax({
			url: data.url,
			method: 'POST',
			data: data,
			error: function (msg) {
				showFeedback(msg, $form);
			},
			success: function (msg) {
				showFeedback(msg, $form);
			}
		});

  }

	function showFeedback(msg, $form) {
	//	console.log(msg);
		$form.find('.submit').removeClass("disabled");
		
		var $feedback = $form.find('.feedback');

		if (msg==100 || msg=="100") {
			$feedback.text(data.thankyou);
			if(feedbackTimeout) clearTimeout(feedbackTimeout);

			feedbackTimeout = setTimeout(function () {
				$feedback.text('');
				$form.find('.email').val('')
			}, feedbackTime);
		} else {
			$feedback.text(data.senderror);
		}
		$form.find('.submit').removeClass("disabled");
	}
	
	function close(e) {
		$popup.removeClass("show");
	}

	function debounce(fn) {
		clearTimeout(debounceTimeout);
		debounceTimeout = setTimeout(fn, debounceTime);
	}

})($(window));