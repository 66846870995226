(function ($w) {
	var isMobile = false;
	var data = {
		location: '',
		status: '',
		priceStart: '',
		priceEnd: '',
		areaStart: '',
		areaEnd: ''
	};
	run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$('.find-home-btn').click(findHome);
	}

	function findHome() {
		setIsMobile();
		collectData();
		redirect();
	}

	function setIsMobile() {
		isMobile = $w.width() < 768 ? true: false;
	}

	function collectData() {
		var location = '';
		var status = '';
		var priceStart = '';
		var priceEnd = '';
		var areaStart = '';
		var areaEnd = '';
		if(isMobile) {
			var $footer = $('.footer');
			location = $footer.find('.location').val();
			status = $footer.find('.status').val();
			priceStart = $footer.find('.price-slider-start').text();
			priceEnd = $footer.find('.price-slider-end').text();
			areaStart = $footer.find('.area-slider-start').text();
			areaEnd = $footer.find('.area-slider-end').text();
		}
		else {
			var $aside = $('.aside');
			location = $aside.find('.location').val();
			status = $aside.find('.status').val();
			priceStart = $aside.find('.price-slider-start').text();
			priceEnd = $aside.find('.price-slider-end').text();
			areaStart = $aside.find('.area-slider-start').text();
			areaEnd = $aside.find('.area-slider-end').text();
		}
		data.location = location;
		data.status = status;
		data.priceStart = removeCommasPoints(priceStart);
		data.priceEnd = removeCommasPoints(priceEnd);
		data.areaStart = removeCommasPoints(areaStart);
		data.areaEnd = removeCommasPoints(areaEnd);
	}

	function removeCommasPoints(str) {
		var s = str;
		s = s.replace(/,/g, '');
		s = s.replace(/\./g, '');
		return s;
	}

	function redirect() {
		url = $(".search_page").val()+"?s=1";
		if (data.location) url+='&location='+data.location;
		if (data.status) url+='&status='+data.status;
		url+='&unit='+$(".search_area_unit").val();
		//url+='&currency='+$(".search_price_currency").val();
		url+='&priceMin='+data.priceStart+'&priceMax='+data.priceEnd+'&areaMin='+data.areaStart+'&areaMax='+data.areaEnd;
		
		//console.log(url);
		location.assign(url);
	}
})($(window));